




































import Vue from "vue";
import ResultOverviewElement from "@/components/ResultOverviewElement.vue";
import Map from "@/components/Map.vue";

export default Vue.extend({
  components: { ResultOverviewElement, Map },
  name: "ResultOverview",
  props: [],
  data() {
    return {
      results: [],
      query: "",
      timeout: 0,
    };
  },
  mounted() {
    this.query = this.$route.params.query ?? "";
    this.getResults(this.query);
  },
  watch: {
    query(newQuery) {
      this.getResults(newQuery);
    },
  },
  methods: {
    getResults(query: string) {
      let requestOptions = {
        method: "GET",
      };

      fetch(
        `https://mdic-backend.herokuapp.com/api/v1/clinic/search?q=${query}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => (this.results = result))
        .catch((error) => console.log("error", error));
    },
  },
});
