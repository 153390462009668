<template>
  <v-hover v-slot="{ hover }">
    <v-card outlined :elevation="hover ? 12 : 0" :class="{ 'on-hover': hover }">
      <v-row>
        <v-col cols="2" md="2">
          <v-img :src="imageUrl" contain class="mdic-image-circle ma-4 pa-4">
          </v-img>
        </v-col>
        <v-col cols="6" md="4"
          ><v-card-title> {{ name }} </v-card-title>
          <v-card-subtitle> {{ type }} </v-card-subtitle>
          <v-card-text>
            <div class="text--primary">
              {{ address_one }}<br />
              {{ address_two }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="teal accent-4"
              @click="reveal = true"
              :to="`/details/${clinicId}`"
            >
              Learn More
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import Vue from "vue";
import imageUrls from "../store/doctorImageUrls";

export default Vue.extend({
  name: "ResultOverviewElement",
  props: {
    name: {
      type: String,
    },
    image: {
      type: String,
    },
    type: {
      type: String,
      default: "Arzt",
    },
    address_one: {
      type: String,
    },
    address_two: {
      type: String,
    },
    clinicId: {
      type: Number,
    },
  },
  computed: {
    imageUrl() {
      return imageUrls[this.clinicId % imageUrls.length];
    },
  },
});
</script>
