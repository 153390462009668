export default [
  "https://image.stern.de/6219354/t/y0/v4/w1440/r1/-/sprueche-arzt-jpg--e18824db7cb6f7d1-.jpg",
  "https://www.medizinjobs-direkt.de/blog/wp-content/uploads/2018/11/05.11.2018_Als-deutscher-Arzt-in-Europa-Karriere-machen.jpg",
  "https://www.erfolg-als-freiberufler.de/wp-content/uploads/2016/11/freiberuflicher-arzt.jpg",
  "https://www.vital.de/sites/default/files/styles/facebook/public/media/2020/04/Arzt-17463.jpg?itok=wDfe33fp",
  "https://www.praktischarzt.de/wp-content/themes/praktischArzt-theme/img/arzt/Assistenzarzt-Final.jpg",
  "https://www.krankenhaus.de/fileadmin/_processed_/a/9/csm_Doktor_No_8a5122cf5f.jpg",
  "https://media.istockphoto.com/photos/portrait-of-mature-male-doctor-wearing-white-coat-standing-in-picture-id1203995945?k=20&m=1203995945&s=612x612&w=0&h=g0_ioNezBqP0NXrR_36-A5NDHIR0nLabFFrAQVk4PhA=",
  "https://www.aerztezeitung.de/Bilder/Eine-Aerztin-und-ein-Arzt-Machen-oft-dieselbe-Arbeit-174931.jpg",
  "https://www.24vita.de/bilder/2020/12/04/90157798/24430344-medikamentenallergie-nebenwirkungen-unvertraeglichkeit-verdacht-arzt-diagnose-vorbereitungen-symbolbild-2q0zFRZ1lUec.jpg",
];
