<template>
  <v-card>
    <l-map style="height: 80vh; z-index: 1" @ready="onReady()" ref="map">
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker
        v-for="(marker, index) of results"
        :key="index"
        :lat-lng="marker.location"
      >
        <l-popup>
          <div @click="innerClick">{{ marker.name }}</div>
        </l-popup>
      </l-marker>
    </l-map>
  </v-card>
</template>

<script>
import Vue from "vue";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";

export default Vue.extend({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  props: {
    results: {
      type: Array,
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      map: null,
    };
  },
  // watches the results array and updates the map
  watch: {
    results: {
      handler() {
        this.onReady();
      },
      deep: true,
    },
  },
  methods: {
    onReady() {
      let map = this.$refs.map.mapObject;
      let data = this.results.map((r) => r.location);
      if (data.length > 0) {
        map.fitBounds(data, { padding: [10, 10] });
      }
    },
    innerClick() {
      this.$emit("innerClick", this.marker);
    },
  },
});
</script>
